<template>
  <div id="ejcon" class="ejcon"  ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
<!-- 头部 -->
         <div class="top-header">
            <dv-decoration-8 class="header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration"  dur="3"  />
            <dv-decoration-8 class="header-right-decoration" :reverse="true" />
            <div class="center-title">项目信息</div>
          </div>
<div class="return" @click="returnBack">
            <img src="@/assets/return.png" alt="">
          </div>

  <!-- 年级 -->
  <div class="classInfo">
    <div class="classInfo_Item" v-for="(item,index) in array" :key="index">
      <dv-border-box-13>
        <h3 class="title fixed1">{{item.title}}</h3>
        <div class="classInfo_Item_con">
 <dv-scroll-board class="dv-scr-board" :config="item.config" />
         
          
        </div>
      </dv-border-box-13>
      
    </div>
  </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      digitalFlopData: [],
      array: [{title: '肺活量',
      config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">年级</span>', 
        '<span style="color:#9abeff;font-weight:bold;">肺活量</span>', 
        '<span style="color:#9abeff;font-weight:bold">考核成绩</span>',
        '<span style="color:#9abeff;font-weight:bold">体育老师</span>'],
        data: [
          ['张良','六年级（2）班', '2200',"优秀", "赵晓霞"],
          ['张兰','四年级（2）班', '1800',"良好", "赵晓霞"],
          ['康宇轩','三年级（2）班', '2100',"优秀", "王珏"],
          ['刘晨皓','六年级（2）班', '1940',"良好", "赵晓霞"],
          ['任子川','四年级（2）班', '1750',"良好", "王珏"],
          ['王浩嘉','三年级（2）班', '1826',"良好", "王珏"],
          ['王文谦', '四年级（2）班', '1430',"及格", "赵晓霞"],
          ['魏梓晨','六年级（2）班', '1900',"良好", "王珏"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','left'],
        columnWidth: [90,170],
      }},{title: '1分钟仰卧起坐',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">年级</span>', 
        '<span style="color:#9abeff;font-weight:bold;">数量</span>', 
        '<span style="color:#9abeff;font-weight:bold">考核成绩</span>',
        '<span style="color:#9abeff;font-weight:bold">体育老师</span>'],
        data: [
          ['王文胤', '三年级（1）班', '49',"优秀", "李希"],
          ['陈心璇', '四年级（4）班', '40',"良好", "赵天"],
          ['安逸', '二年级（2）班', '46',"优秀", "李希"],
          ['陈心璇','三年级（3）班', '42',"良好", "刘明"],
          ['戴熠默', '四年级（1）班', '43',"优秀", "李希"],
          ['董佳琪', '二年级（2）班', '37',"良好", "赵天"],
          ['韩尚希','四年级（3）班', '28',"及格", "赵天"],
          ['何姝菡','三年级（5）班', '40',"良好", "李希"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [90,170],
      }},{title: '50米跑',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">年级</span>', 
        '<span style="color:#9abeff;font-weight:bold;">时间</span>', 
        '<span style="color:#9abeff;font-weight:bold">考核成绩</span>',
        '<span style="color:#9abeff;font-weight:bold">体育老师</span>'],
        data: [
          ['胡蝶', '五年级（1）班', '23秒',"优秀", "李希"],
          ['李朵',  '四年级（5）班', '36秒',"良好", "刘明"],
          ['李牧瑶', '二年级（2）班', '42秒',"良好", "赵天"],
          ['李奕诺', '四年级（4）班', '36秒',"优秀", "李希"],
          ['任子川', '五年级（2）班', '30秒',"优秀", "赵天"],
          ['赵梓潼', '二年级（4）班', '29秒',"优秀", "刘明"],
          ['申钰淇', '四年级（3）班', '41秒',"良好", "赵天"],
          ['赵奕莹',  '二年级（1）班', '39秒',"良好", "李希"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [90,170],
      }},{title: '800米跑',config: {
         header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">年级</span>', 
        '<span style="color:#9abeff;font-weight:bold;">时间</span>', 
        '<span style="color:#9abeff;font-weight:bold">考核成绩</span>',
        '<span style="color:#9abeff;font-weight:bold">体育老师</span>'],
        data: [
          ['魏祎',  '三年级（2）班', '4分20秒',"及格", "高大恩"],
          ['许梦棋', '二年级（3）班', '3分05秒',"良好", "赵天"],
          ['薛善溪',  '六年级（4）班', '3分26秒',"优秀", "刘明"],
          ['杨谨帆', '三年级（1）班', '3分56秒',"及格", "高大恩"],
          ['陈语萌',  '四年级（2）班', '3分16秒',"优秀", "赵天"],
          ['刘仕娜',  '六年级（1）班', '3分18秒',"优秀", "刘明"],
          ['王文谦', '三年级（4）班', '3分12秒',"良好", "高大恩"],
          ['赵梓瑶',  '六年级（2）班', '4分06秒',"及格", "刘明"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [90,170],
      }},{title: '一分钟跳绳',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">年级</span>', 
        '<span style="color:#9abeff;font-weight:bold;">数量</span>', 
        '<span style="color:#9abeff;font-weight:bold">考核成绩</span>',
        '<span style="color:#9abeff;font-weight:bold">体育老师</span>'],
        data: [
         ['赵晓峰','三年级（1）班', '121',"优秀", "刘明"],
          ['张兰','五年级（3）班', '89',"及格", "高大恩"],
          ['高彬泽','五年级（2）班', '96',"及格", "刘明"],
          ['张琪','三年级（1）班', '136',"优秀", "高大恩"],
          ['田佳浩','四年级（5）班', '130',"优秀", "刘明"],
          ['王浩阳','四年级（2）班', '119',"优秀", "高大恩"],
          ['高子瑾', '三年级（4）班', '136',"优秀", "刘明"],
          ['郭静溪', '五年级（3）班', '132',"优秀", "刘明"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [90,170],
      }},
      {title: '立定跳远',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">年级</span>', 
        '<span style="color:#9abeff;font-weight:bold;">距离</span>', 
        '<span style="color:#9abeff;font-weight:bold">考核成绩</span>',
        '<span style="color:#9abeff;font-weight:bold">体育老师</span>'],
        data: [
          
           ['陈默','四年级（1）班', '1.46',"及格", "高大恩"],
          ['高子瑾', '四年级（1）班', '1.73',"优秀", "李希"],
          ['高彬泽', '四年级（1）班', '1.68',"良好", "李希"],
          ['郭鑫垚', '四年级（1）班', '1.76',"优秀", "李希"],
          ['田佳浩', '三年级（5）班', '1.56',"及格", "高大恩"],
          ['李嘉毅', '四年级（1）班', '1.68',"良好", "高大恩"],
          ['王浩阳', '四年级（1）班', '1.72',"优秀", "高大恩"],
          ['郭静溪', '二年级（2）班', '1.7',"优秀", "李希"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [90,170],
      }}
      ]
      
    }
  },
  components: {
   
  },
  mounted() {
    this.cancelLoading()
    
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
  //  加载动画
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

   
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    },
     returnBack(){
      this.$router.go(-1)
    },

  }
}
</script>

<style lang="scss" scoped>
.title{
  padding: 30px 24px;
    font-size: 22px;
    color: #12cfd8;
}
.bg{
  padding: 0;
}
.ejcon {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;

  .host-body {
.top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px;
  margin-bottom: 16px;

  .header-center-decoration {
    width: 46%;
    height: 60px;
    margin-top: 20px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 28px;
    transform: translateX(-50%);
    letter-spacing:8px;
    color: #0ebe9b;
  }
}
.return{
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  left: 20px;
  top: 52px;
  width: 80px;
  height: 80px;
  background: rgba(0, 250, 255, 0.7);
  border-radius: 50%;
  border: 1px solid #0f319b;
  img{
    display: block;
    text-align: center;
    width: 36px;
    margin: 24px auto;
  }
}
  }

  
.classInfo{
  margin: 0 20px;
  >div:nth-child(3n){
    margin-right: 0;
  }
  >div:nth-child(-n+3){
    margin-bottom: 36px;
  }
  .classInfo_Item{
    width: 610px;
    height: 440px;
    float: left;
    font-size: 20px ;
    margin-right: 20px;
      .dv-scr-board {
       padding: 0 20px;
    width: 609px;
    height: 352px;
      color: #d4eaf6;
      ::v-deep .header-item{
        font-size: 18px !important;
      }
      ::v-deep .row-item{
        height: 58px !important;
        line-height:58px !important;
        font-size: 18px !important;
        color: #9abeff !important;
      }
      
    }
    
  }
}

}
</style>
